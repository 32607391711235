<template>
	<v-card
		elevation="0"
		class="ma-0 pa-0"
	>
		<v-card-text class="pl-0 py-4">
			<v-row justify="space-between">
				<v-col cols="auto" class="py-0">
					<v-alert
						dense
						outlined
						border="left"
						:value="errorOn && !dialog"
						:type="errorType"
						class="ma-0 py-1"
					>
						{{ errorMessage }}
						<font v-if="!isLoaded" @click="getEducation" style="cursor:pointer;"><strong>, click here</strong> to reload</font>
					</v-alert>
				</v-col>

				<v-col cols="auto" class="text-right py-0 mb-1">
					<template>
						<v-tooltip bottom nudge-bottom="-15">
							<template v-slot:activator="{ on: onToolTip }">
								<v-icon
									size="30"
									:color="hoverNew? 'blue darken-1' : 'blue darken-2'"
									v-on="onToolTip"
									@mouseover="hoverNew = !hoverNew"
									@mouseleave="hoverNew = !hoverNew"
									@click="newItem"
								>mdi-plus-circle</v-icon>
							</template>
							<small>New</small>
						</v-tooltip>
					</template>
					
					<template>
						<v-tooltip bottom nudge-bottom="-15">
							<template v-slot:activator="{ on: onToolTip }">
								<v-icon
									size="30"
									:color="hoverRefresh? 'blue darken-1' : 'blue darken-2'"
									v-on="onToolTip"
									@mouseover="hoverRefresh = !hoverRefresh"
									@mouseleave="hoverRefresh = !hoverRefresh"
									@click="getEducation"
								>mdi-refresh-circle</v-icon>
							</template>
							<small>Refresh</small>
						</v-tooltip>
					</template>
				</v-col>
			</v-row>
		</v-card-text>
		
		<v-card-text class="pa-0 ma-0 pr-1">
			<v-data-table
				:dense="$vuetify.breakpoint.lgAndDown"
				fixed-header
				:headers="headers"
				:height="tableHeight"
				:items="education"
				:loading="dataTableLoading"
				loading-text="Loading... Please wait"
				no-data-text="No records found."
				:options.sync="tableOptions"
				:page="tableOptions.page"
				:server-items-length="tableTotal"
				ref="dataTableEducation"
				@update:items-per-page="tableResize; getEducation();"
				@update:page="getEducation"
				@update:sort-desc="getEducation"
			>
				<template v-slot:header.EDUC_LEVEL="{ header }">
					{{ header.text }}<br/>DEGREE/COURSE/SPECIALIZATION
				</template>
				
				<template v-slot:item.EDUC_LEVEL="{ item }" >
					<div style="min-height:20px;">{{ item.EDUC_LEVEL }}</div>
					<div v-if="$vuetify.breakpoint.smAndUp" style="min-height:20px;">{{ item.EDUC_COURSE }}</div>
				</template>
				
				<template v-slot:header.SCHOOL="{ header }">
					{{ header.text }}<br/>SCHOOL ADDRESS
				</template>
				
				<template v-slot:item.SCHOOL="{ item }" >
					<div style="min-height:20px;">{{ item.SCHOOL }}</div>
					<div v-if="$vuetify.breakpoint.smAndUp" style="min-height:20px;">{{ item.SCHOOL_ADDRESS }}</div>
				</template>
				
				<template v-slot:item.action="{ item }">
					<v-tooltip bottom>
						<template v-slot:activator="{ on: onToolTip }">
							<v-icon small v-on="onToolTip" color="success" @click="editItem(item)">mdi-pencil</v-icon>
						</template>
						<small>Edit</small>
					</v-tooltip>
					
					<v-tooltip bottom>
						<template v-slot:activator="{ on: onToolTip }">
							<v-icon small v-on="onToolTip" color="error" @click="dialogDelete = true;deleteIndex = item">mdi-delete</v-icon>
						</template>
						<small>Delete</small>
					</v-tooltip>
				</template>
			</v-data-table>
			
			<v-dialog
				v-model="dialog"
				:fullscreen="$vuetify.breakpoint.xsOnly && !dialogLoading"
				max-width="650"
				:persistent="dialogLoading"
				scrollable
				@click:outside="resetFormValidation()"
				@keydown.enter="saveItem"
			>
				<v-card v-if="!dialogLoading">
				
					<v-card-title class="headline primary white--text" primary-title>
						{{ dialogTitle }}
						
						<v-spacer></v-spacer>
							
						<v-btn icon
							@click="$refs.formEducation.resetValidation(); dialog = false;"
							class="white--text"
						>
							<v-icon small dark>mdi-close</v-icon>
						</v-btn>
					</v-card-title>
					
					<v-card-text>
						<v-form
							ref="formEducation"
							v-model="validForm"
							lazy-validation
						>
							<v-row dense no-gutters>
								<v-col cols="12">
									<v-alert
										dense
										outlined
										border="left"
										:value="errorOn && dialog"
										:type="errorType"
										class="mt-2 py-1"
									>
										{{ errorMessage }}
									</v-alert>
								</v-col>
								
								<v-col cols="12">
									<v-select
										label="Education Level*"
										v-model="editedItem.EDUC_LEVEL"
										:items="educationLevel"
										item-text="description"
										item-value="code"
										:rules="rulesEducationLevel"
										@change="changeEducationLevel()"
									></v-select>
								</v-col>
								
								<v-col cols="12">
									<v-autocomplete
										ref="newCourse"
										:label="labelEducationCourse"
										v-model="editedItem.EDUC_COURSE"
										v-if="!newCourse"
										clearable
										:items="optionCourse"
										item-text="COURSE"
										item-value="COURSE"
										:rules="rulesCourse"
										validate-on-blur
										:search-input.sync="searchCourse"
										:loading="searchingCourse"
										:disabled="!requiredCourse"
										:no-data-text= "courseNoDataText"
										:allow-overflow=false
										@focus="courseOn = true;"
										@blur="courseOn = false;"
									>
										<template v-slot:append-outer>
											<v-tooltip bottom>
												<template v-slot:activator="{ on: onToolTip }">
													<v-icon left v-on="onToolTip" @click="newCourse = !newCourse; oldCourse = editedItem.EDUC_COURSE; editedItem.EDUC_COURSE = '';">mdi-plus</v-icon>
												</template>
												<small>Others</small>
											</v-tooltip>
										</template>
									</v-autocomplete>
									
									<v-text-field
										ref="newCourseNew"
										:label="labelEducationCourse"
										v-model="editedItem.EDUC_COURSE"
										v-if="newCourse"
										:disabled="!requiredCourse"
										hint="Please specify here"
										:rules="rulesCourse"
										validate-on-blur
										:persistent-hint="requiredCourse"
										@blur="editedItem.EDUC_COURSE = trimName(editedItem.EDUC_COURSE)"
										class="uppercase-input"
									>
										<template v-slot:append-outer>
											<v-tooltip bottom>
												<template v-slot:activator="{ on: onToolTip }">
													<v-icon class="pa-0 ma-0" left v-on="onToolTip" @click="newCourse = !newCourse; editedItem.EDUC_COURSE = oldCourse;">mdi-format-list-bulleted</v-icon>
												</template>
												<small>Select</small>
											</v-tooltip>
										</template>
									</v-text-field>
								</v-col>
								
								<v-col cols="12">
									<v-text-field
										label="Name of School*"
										v-model="editedItem.SCHOOL"
										:rules="rulesSchoolName"
										validate-on-blur
										@blur="editedItem.SCHOOL = trimName(editedItem.SCHOOL)"
										class="uppercase-input"
									>
									</v-text-field>
								</v-col>
								
								<v-col cols="12">
									<v-autocomplete
										label="School Address*"
										v-model="editedItem.SCHOOL_ADDRESS"
										v-if="!newSchoolAddress"
										hint="City, Province"
										persistent-hint
										clearable
										:items="optionSchoolAddress"
										item-text="cityAndProvince"
										item-value="cityAndProvince"
										:rules="rulesSchoolAddress"
										validate-on-blur
										:search-input.sync="searchSchoolAddress"
										:loading="searchingSchoolAddress"
										:no-data-text= "schoolAddressNoDataText"
										:allow-overflow=false
										@focus="addressOn = true;"
										@blur="addressOn = false;"
									>
										<template v-slot:append-outer>
											<v-tooltip bottom>
												<template v-slot:activator="{ on: onToolTip }">
													<v-icon left v-on="onToolTip" @click="newSchoolAddress = !newSchoolAddress; oldSchoolAddress = editedItem.SCHOOL_ADDRESS; editedItem.SCHOOL_ADDRESS = '';">mdi-plus</v-icon>
												</template>
												<small>Others</small>
											</v-tooltip>
										</template>
									</v-autocomplete>
									
									<v-text-field
										label="School Address*"
										v-model="editedItem.SCHOOL_ADDRESS"
										v-if="newSchoolAddress"
										hint="Please specify here (eg. City, Province)"
										persistent-hint
										:rules="rulesSchoolAddress"
										validate-on-blur
										@blur="editedItem.SCHOOL_ADDRESS = trimName(editedItem.SCHOOL_ADDRESS)"
										class="uppercase-input"
									>
										<template v-slot:append-outer>
											<v-tooltip bottom>
												<template v-slot:activator="{ on: onToolTip }">
													<v-icon left v-on="onToolTip" @click="newSchoolAddress = !newSchoolAddress; editedItem.SCHOOL_ADDRESS = oldSchoolAddress;">mdi-format-list-bulleted</v-icon>
												</template>
												<small>Select</small>
											</v-tooltip>
										</template>
									</v-text-field>
								</v-col>
								
								<v-col cols="12">
									<v-text-field
										ref="inputYearStart"
										label="Year Started*"
										v-model="editedItem.EDUC_START"
										hint="YYYY"
										v-mask= "maskYear"
										:rules="rulesYearStart"
										validate-on-blur
										@change="(editedItem.EDUC_FINISH > 0)? $refs.inputYearFinished.validate() : ''"
									></v-text-field>
								</v-col>
								
								<v-col cols="12">
									<v-text-field
										ref="inputYearFinished"
										label="Year Finished*"
										v-model="editedItem.EDUC_FINISH"
										hint="YYYY"
										v-mask= "maskYear"
										:rules="rulesYearFinished"
										validate-on-blur
										@change="(editedItem.EDUC_START > 0)? $refs.inputYearStart.validate() : ''"
									></v-text-field>
								</v-col>
								
								<v-col cols="12">
									<v-select
										v-model="editedItem.STATUS"
										label="Status*"
										:items="educationStatus"
										item-text="description"
										item-value="code"
										:rules="rulesStatus"
										validate-on-blur
									>
									</v-select>
								</v-col>
							</v-row>
						</v-form>
						
					</v-card-text>
					
					<v-card-actions>
						<small class="pl-4 font-weight-light">Note: Field with asterisk(*) is required.</small>
						<v-spacer></v-spacer>
						<v-btn color="blue darken-1" text @click="$refs.formEducation.resetValidation(); dialog = false;">Cancel</v-btn>
						<v-btn color="blue darken-1" text @click="saveItem">Save</v-btn>
					</v-card-actions>
				</v-card>
				
				<v-card
					color="primary"
					dark
					v-if="dialogLoading"
				>
					<v-card-text class="pt-2">
						Saving...
						<v-progress-linear
							indeterminate
							color="white"
							class="mb-0"
						></v-progress-linear>
					</v-card-text>
				</v-card>
			</v-dialog>
			
			<v-dialog
				v-model="dialogDelete"
				max-width="355"
				:persistent="dialogLoading"
			>
				<v-card
					color="primary"
					dark
					v-if="dialogLoading"
				>
					<v-card-text class="pt-2">
						Deleting...
						<v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
					</v-card-text>
				</v-card>
				
				<v-card v-else-if="!dialogLoading">
					<v-card-title class="subtitle-1 warning white--text">CONFIRM DELETE</v-card-title>
					
					<v-card-text class="subtitle-1 font-weight-medium pt-5">Are you sure you want to delete this item?</v-card-text>
					
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="blue darken-1" text @click="dialogDelete = false">NO</v-btn>
						<v-btn color="blue darken-1" text @click="deleteItem()">YES</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-card-text>
	</v-card>
	
</template>

<script>
import { CLEAR_ALERT } from '@/store/actions/user';

import {mask} from 'vue-the-mask';
import { mapGetters } from 'vuex';

var timeoutData				= null;
var timoutLoading			= null;
var timeoutDropdown			= null;
var timeoutCourse			= null;
var timeoutWindowResize		= null;
var timeoutTableResize		= null;
var timeoutSchoolAddress	= null;
var timeoutDialogClose		= null;

export default {
	directives: {mask},
	data () {
		return {
			tableTotal			: 0,
			tableOptions		: {	page: 1, itemsPerPage: 10, sortBy: ['EDUC_START'], sortDesc: [false] },
			
			hoverNew				: false,
			hoverRefresh			: false,
			
			newCourse				: false,
			oldCourse				: '',
			optionCourse			: [],
			searchCourse			: null,
			searchingCourse			: false,
			courseNoDataText		: 'Start typing to search',
			courseOn				: false,
			
			newSchoolAddress		: false,
			oldSchoolAddress		: false,
			optionSchoolAddress		: [],
			searchSchoolAddress		: null,
			searchingSchoolAddress	: false,
			schoolAddressNoDataText	: 'Start typing to search',
			addressOn				: false,
			
			yearMax					: '',
			yearMin					: '',
			
			isLoaded				: false,
			
			dialog					: false,
			validForm				: true,
			dialogDelete			: false,
			dialogLoading			: false,
			dataTableLoading		: true,
			isLoadedOptions			: false,
			
			tableHeight				: 'undefined',
			
			requiredCourse			: false,
			
			education				: [],
			educationLevel			: [],
			educationCourse			: [],
			educationStatus			: [],
			
			maskYear				: '####',
			
			deleteIndex				: [],
			editedIndex				: -1,
			editedItem: {
				ID					: '',
				EDUC_LEVEL			: '',
				EDUC_COURSE			: '',
				SCHOOL				: '',
				SCHOOL_ADDRESS		: '',
				EDUC_START			: '',
				EDUC_FINISH			: '',
				STATUS				: '',
			},
			defaultItem: {
				ID					: '',
				EDUC_LEVEL			: '',
				EDUC_COURSE			: '',
				SCHOOL				: '',
				SCHOOL_ADDRESS		: '',
				EDUC_START			: '',
				EDUC_FINISH			: '',
				STATUS				: '',
			},
			headers: [
				{ text: 'LEVEL',			value: 'EDUC_LEVEL',	sortable: false,	align: 'start' },
				{ text: 'COURSE / DEGREE',	value: 'EDUC_COURSE',	sortable: false,	align: ' d-none' },
				{ text: 'SCHOOL NAME',		value: 'SCHOOL',		sortable: false },
				{ text: 'SCHOOL ADDRESS',	value: 'SCHOOL_ADDRESS',sortable: false,	align: ' d-none' },
				{ text: 'YEAR STARTED',		value: 'EDUC_START',	align: 'center' },
				{ text: 'YEAR FINISHED',	value: 'EDUC_FINISH',	sortable: false,	align: 'center' },
				{ text: 'STATUS',			value: 'STATUS',		sortable: false,	align: 'center' },
				{ text: 'ACTIONS',			value: 'action',		sortable: false,	align: 'center' },
			],
			rulesEducationLevel: [
				v => !!v || 'Education level is required',
			],
			rulesCourse: [
				v => ((!!v && this.requiredCourse) || !this.requiredCourse) || 'Course is required',
				v => ((!!v && this.stringLength(v) >= 5) || (this.stringLength(v) == 0)) || 'Must be atleast 5 characters',
				v => ((!!v && this.stringLength(v) <= 100) || (this.stringLength(v) == 0)) || 'Must be less than 100 characters',
			],
			rulesSchoolName: [
				v => !!v || 'Name of school is required',
				v => (!!v && this.stringLength(v) >= 2) || 'Must be atleast 2 characters',
				v => (!!v && this.stringLength(v) <= 100) || 'Must be less than 100 characters',
				v => !/[^a-zA-Z0-9ñÑ.,&-\s]+/.test(v) || 'Must be alpha characters only',
			],
			rulesSchoolAddress: [
				v => !!v || 'School address is required',
				v => (!!v && this.stringLength(v) >= 5) || 'Must be atleast 5 characters',
				v => (!!v && this.stringLength(v) <= 100) || 'Must be less than 100 characters',
				v => !/[^a-zA-Z0-9ñÑ.,-\s]+/.test(v) || 'Must be alpha characters only',
			],
			rulesYearStart: [
				v => !!v || 'Year started is required',
				(v) => this.yearRange(v) || this.yearRangeError,
				v => ( v <= this.editedItem.EDUC_FINISH || !this.yearRange(this.editedItem.EDUC_FINISH) ) || 'Must be equal or less than year finished',
			],
			rulesYearFinished: [
				v => !!v || 'Year finished is required',
				(v) => this.yearRange(v) || this.yearRangeError,
				v => ( v >= this.editedItem.EDUC_START || !this.yearRange(this.editedItem.EDUC_START) ) || 'Must be equal or greater than year started',
			],
			rulesStatus: [
				v => !!v || 'Status is required',
			],
		}
	},
	methods: {
		getEducation () {
			if(this.dataTableLoading && typeof source !== 'undefined')source.cancel();
			
			const CancelToken = this.$http.CancelToken;
			let source = CancelToken.source();

			this.$store.dispatch(CLEAR_ALERT);
			this.dataTableLoading = true;
			this.isLoaded = false;
			
			clearTimeout(timeoutData);
				
			this.$http.get('education', { params:{ options: this.tableOptions } }, { cancelToken: source.token })
			.then((resp) => {
				this.education = resp.data.data;
				this.tableTotal = resp.data.rows;
				this.isLoaded = true;
				this.dropdown();
			})
			.catch(() => {
				timeoutData = setTimeout(() => {
					this.getEducation();
				}, 60000);
			})
			.finally(() => {
				this.dataTableLoading = false;
				this.tableResize();
			});
		},
		newItem: function(){
			this.$store.dispatch(CLEAR_ALERT);
			this.dialog = true;
			
			setTimeout(() => {
				if(typeof this.$refs.formEducation !== 'undefined')this.$refs.formEducation.resetValidation();
			}, 50);
		},
		editItem: function(item){
			this.changeEducationLevel();
			
			if(item.EDUC_COURSE != '')this.optionCourse.push( {'COURSE': item.EDUC_COURSE} );
			if(item.SCHOOL_ADDRESS != '')this.optionSchoolAddress.push( {'cityAndProvince': item.SCHOOL_ADDRESS} );
			
			this.requiredCourse = (item.EDUC_LEVEL == 'ELEMENTARY' || item.EDUC_LEVEL == 'HIGH SCHOOL')? false : true;
			
			this.editedIndex = this.education.indexOf(item);
			this.editedItem = Object.assign({}, item);
			
			this.$store.dispatch(CLEAR_ALERT);
			this.dialog = true;
		},
		deleteItem: function(){
			this.$store.dispatch(CLEAR_ALERT);
			this.dialogLoading = true;
			this.$http.delete('education', {
				params: {
					ID: this.deleteIndex.ID
				}
			})
			.then(() => {
				const index = this.education.indexOf(this.deleteIndex);
				this.education.splice(index, 1);
				this.tableTotal--;
				this.tableResize();
			})
			.finally(() => {
				this.dialogDelete = false;
				clearTimeout(timoutLoading);
				timoutLoading = setTimeout(() => {
					this.dialogLoading = false;
				}, 300);
			});
		},
		saveItem: function(){
			if(this.$refs.formEducation.validate()){
				this.$store.dispatch(CLEAR_ALERT);
				this.dialogLoading = true;
			
				if(this.editedIndex > -1){//Edit Item
					this.$http.put('education', {
						forms:				this.editedItem,
						newCourse:			this.newCourse,
						newSchoolAddress:	this.newSchoolAddress
					})
					.then(() => {
						Object.assign(this.education[this.editedIndex], this.editedItem);
						this.tableResize();
						this.dialog = false;
					})
					.catch(() => {
						this.dialog = true;
					})
					.finally(() => {
						clearTimeout(timoutLoading);
						timoutLoading = setTimeout(() => {
							this.dialogLoading = false;
						}, 300)
					});
				}else{//New Item
					this.$http.post('education', {
						forms:				this.editedItem,
						newCourse:			this.newCourse,
						newSchoolAddress:	this.newSchoolAddress
					}).then((response) => {
						this.editedItem.ID = response.data.ID;
						
						this.tableTotal++;
						this.education.unshift(this.editedItem);
						
						this.tableResize();
						this.dialog = false;
					})
					.catch(() => {
						this.dialog = true;
					})
					.finally(() => {
						clearTimeout(timoutLoading);
						timoutLoading = setTimeout(() => {
							this.dialogLoading = false;
						}, 300)
					});
				}
			}
		},
		tableResize(){
			clearTimeout(timeoutTableResize);
			timeoutTableResize = setTimeout(() => {
				if(typeof this.$refs.dataTableEducation !== 'undefined'){
					const windowWidth = this.$refs.dataTableEducation.$parent.$el.clientWidth;
					const tableWidth = this.$refs.dataTableEducation.$el.children[0].children[0].clientWidth;
					const scrollX = ( tableWidth > windowWidth)? 20 : 0;
					
					const tbodyHeight= this.$refs.dataTableEducation.$el.children[0].children[0].clientHeight;
					this.tableHeight = ( (this.userFormHeight - (40 + 65) ) > tbodyHeight )? tbodyHeight : this.userFormHeight - (40 + 65);
					this.tableHeight += scrollX;
				}
			}, 50);
		},
		dropdown(){
			if(!this.isLoadedOptions){
				this.$http.get('dropdown/educationDropdowns').then((resp) => {
					this.educationLevel = resp.data.educationLevel;
					this.educationStatus = resp.data.educationStatus;
					this.yearMin = resp.data.minYear;
					this.yearMax = resp.data.maxYear;
				})
				.then(() => {
					this.isLoadedOptions = true;
				})
				.catch(() => {//retry
					clearTimeout(timeoutDropdown);
					timeoutDropdown = setTimeout(() => {
						this.dropdown();
					}, 10000)
				});
			}
		},
		resetFormValidation(){
			if(!this.dialogLoading)this.$refs.formEducation.resetValidation();
		},
		changeEducationLevel(){
			this.optionCourse = [];
			this.courseNoDataText = 'Start typing to search';
			this.requiredCourse = (this.editedItem.EDUC_LEVEL == 'ELEMENTARY' || this.editedItem.EDUC_LEVEL == 'HIGH SCHOOL')? false : true;
			if(!this.requiredCourse){
				this.editedItem.EDUC_COURSE = '';
				this.newCourse = false;
			}
			
			if(typeof this.$refs.newCourseNew !== 'undefined'){
				this.$refs.newCourseNew.validate();
			}
			if(typeof this.$refs.newCourse !== 'undefined'){
				this.$refs.newCourse.validate();
			}
		},
		yearRange(year){
			return ( year >= this.yearMin && year <= this.yearMax )? true : false;
		},
		stringLength(text){
			return (typeof text === 'undefined' || text === null)? 0 : text.length;
		},
		trimName(text){
			return (typeof text === 'undefined')? '' : text.toUpperCase().replace('ñ', 'Ñ').replace(/ +/g, ' ').trimStart().trim();
		},
		closeDialog () {
			this.dialog = false
			clearTimeout(timeoutDialogClose);
			timeoutDialogClose = setTimeout(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
				
				this.requiredCourse = this.newCourse = this.newSchoolAddress = false;
			}, 50);
		},
	},
	computed: {
		...mapGetters(['userFormHeight', 'serverDate', 'windowSize', 'errorOn', 'errorType', 'errorMessage']),
		yearRangeError(){
			return 'Out of range ('+this.yearMin+' - '+this.yearMax+')';
		},
		dialogTitle () {
			return this.editedIndex === -1 ? 'New Education' : 'Edit Education'
		},
		labelEducationCourse (){
			return this.requiredCourse? "Degree / Course / Specialization*" : "Degree / Course / Specialization";
		},
	},
	watch: {
		windowSize(newVal, oldVal){
			if( newVal != oldVal ){
				clearTimeout(timeoutWindowResize);
				timeoutWindowResize = setTimeout(() => {
					this.tableResize();
				}, 600)
			}
		},
		dialog (val) {
			val || this.closeDialog()
		},
		searchCourse(val){
			if(!this.courseOn) return
			
			clearTimeout(timeoutCourse);
			
			timeoutCourse = setTimeout(() => {
				this.searchingCourse = true;
				this.courseNoDataText = "Searching..."
				this.$http.get('autocomplete/educationCourse', {
					params: {
						search	: val,
						level	: this.editedItem.EDUC_LEVEL,
					}
				})
				.then((res) => {
					this.optionCourse = res.data;
				}).finally(() => {
					this.searchingCourse = false;
					this.courseNoDataText = (this.optionCourse.length == 0)? "No data available" : "Searching...";
				});
			}, 500);
		},
		searchSchoolAddress(val){
			if(!this.addressOn) return
			
			clearTimeout(timeoutSchoolAddress);
			
			timeoutSchoolAddress = setTimeout(() => {
				this.searchingSchoolAddress = true;
				this.schoolAddressNoDataText = "Searching..."
				this.$http.get('autocomplete/citiesAndProvinces', {
					params: {
						search: val
					}
				})
				.then((res) => {
					this.optionSchoolAddress = res.data;
				}).finally(() => {
					this.searchingSchoolAddress = false;
					this.schoolAddressNoDataText = (this.optionSchoolAddress.length == 0)? "No data available" : "Searching...";
				});
			}, 500);
		},
	},
	created () {
		// fetch the data when the view is created and the data is
		// already being observed
		this.getEducation();
	},
	mounted (){
		this.yearMax = this.serverDate.substr(0, 4);
		this.yearMin = this.serverDate.substr(0, 4) - 60;
	},
	beforeDestroy(){
		clearTimeout(timeoutData);
		clearTimeout(timoutLoading);
		clearTimeout(timeoutDropdown);
		clearTimeout(timeoutCourse);
		clearTimeout(timeoutTableResize);
		clearTimeout(timeoutWindowResize);
		clearTimeout(timeoutSchoolAddress);
		clearTimeout(timeoutDialogClose);
	},
}
</script>

<style scoped>
	.uppercase-input >>> input {
		text-transform: uppercase
    }
</style>